import { useActiveStoreView } from '@emico-hooks/use-active-storeview'
import styled from '@emotion/styled'
import { Trans, t } from '@lingui/macro'
import { useRouter } from 'next/router'
import React from 'react'

import { ChevronLeftIcon, ChevronRightIcon } from '@emico/icons'
import { maxWidth, minWidth } from '@emico/styles'

import removeLeadingSlashFromUrl from '../lib/removeLeadingSlashFromUrl'
import { useStoreConfig } from '../lib/storeConfig'
import unslugify from '../lib/unslugify'
import theme from '../theme'
import NextLink from './NextLink'
import SchemaBreadcrumbs from './SchemaBreadcrumbs'

const BreadcrumbsWrapper = styled.div`
  margin-top: ${theme.spacing.lg};
`

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const Item = styled.li`
  display: flex;
  align-items: center;
`

const BreadcrumbsMobile = styled.div`
  @media ${minWidth('md')} {
    display: none;
  }
`

const BreadcrumbsTabletAndDesktop = styled(List)`
  display: flex;
  align-items: center;

  @media ${maxWidth('sm')} {
    display: none;
  }
`

const StyledNextLink = styled(NextLink)`
  display: flex;
  align-items: center;
  color: ${theme.colors.grayDark};
  text-decoration: none;

  &:hover {
    color: ${theme.colors.grayDark};
    text-decoration: none;
  }

  @media ${minWidth('lg')} {
    ${theme.animation.linkHover()};
  }
`

const Name = styled.span`
  color: ${theme.colors.grayDark};
`

const StyledChevronLeftIcon = styled(ChevronLeftIcon)`
  margin-right: ${theme.spacing.xs};
  font-size: 12px;
`

const StyledChevronRightIcon = styled(ChevronRightIcon)`
  margin: 0 ${theme.spacing.xxs};
  font-size: 12px;
`

const HomeCrumb = ({ hasChevronLeft }: { hasChevronLeft?: boolean }) => (
  <StyledNextLink analyticsContext="breadcrumbs" analyticsName="home" href="/">
    {hasChevronLeft && <StyledChevronLeftIcon />}

    <Trans>Home</Trans>
  </StyledNextLink>
)

interface BreadcrumbParent {
  /**
   * Parent name displayed as breadcrumb
   */
  name: string

  /**
   * Parent url to create breadcrumb link
   */
  url: string
}

interface BreadcrumbName {
  /**
   * Custom name displayed as breadcrumb
   */
  name?: string

  /**
   * Custom url for SEO reasons
   * Only has to be passed to prevent duplicate content, e.g. a canonicalUrl to prevent multiple url paths to the same product
   */
  url?: string
}

interface Props {
  /**
   * Array of custom parent objects if parent crumbs should differ from automatic next/router path based parent crumbs
   */
  customParents?: BreadcrumbParent[]

  /**
   * Custom name object if the current page name should differ from next/router path
   */
  customName?: BreadcrumbName

  /**
   * Should parents be included as crumbs?
   */
  showParents?: boolean

  /**
   * Should homepage be included as crumb?
   */
  showHome?: boolean

  /**
   * Should  current page be included as crumb?
   */
  showName?: boolean
}

/**
 * Component to create breadcrumb links
 * Automatically creates crumbs, based on next/router slug structure
 * e.g. url "/customer-service/guarantee" creates crumbs "Home > Customer service > Guarantee"
 * Crumbs can differ from next/router slug structure by passing customParents and/or customName
 * e.g. url "/information/go-carts" can create crumbs "Home > Everything about go carts" when props are used
 */

const Breadcrumbs = ({
  customParents,
  customName,
  showParents = true,
  showHome = true,
  showName = true,
  ...other
}: Props) => {
  const activeStoreView = useActiveStoreView()
  const router = useRouter()
  const { storeConfig } = useStoreConfig()
  const baseUrl = storeConfig?.baseUrl
  const completePath = router.asPath
  const routerSlugs = Array.isArray(router.query.slug) && router.query.slug
  const completePathSlugs =
    completePath &&
    completePath
      .split('?')[0]
      .toString()
      .split('/')
      .filter((slug) => slug !== '')
  const slugs = routerSlugs || completePathSlugs || []
  const parentSlugs = slugs.slice(0, -1)
  const namePath = slugs.slice(-1)[0]
  const nameSlug = unslugify(namePath)
  const lastParent = parentSlugs.slice(-1)[0]
  const pathBeforeLastParent = completePath.substring(
    0,
    completePath.indexOf(lastParent),
  )
  const lastCustomParent = customParents?.slice(-1)[0]

  const schemaHome = showHome
    ? [
        {
          url: `${baseUrl}`,
          name: t({ message: 'Home' }),
        },
      ]
    : []

  const schemaName = [
    {
      url: new URL(
        removeLeadingSlashFromUrl(customName?.url ?? completePath),
        baseUrl ?? activeStoreView.basename,
      ).toString(),
      name: customName?.name ?? nameSlug,
    },
  ]

  const schemaParents = customParents
    ? customParents.map((parent) => ({
        url: new URL(
          removeLeadingSlashFromUrl(parent.url),
          baseUrl ?? activeStoreView.basename,
        ).toString(),
        name: parent.name,
      }))
    : parentSlugs.map((parent) => {
        const pathBeforeParent = completePath.substring(
          0,
          completePath.indexOf(parent),
        )

        return {
          url: new URL(
            removeLeadingSlashFromUrl(`${pathBeforeParent}${parent}`),
            baseUrl ?? activeStoreView.basename,
          ).toString(),
          name: unslugify(parent),
        }
      })

  return (
    <>
      <SchemaBreadcrumbs
        items={[...schemaHome, ...schemaParents, ...schemaName]}
      />

      <BreadcrumbsWrapper {...other}>
        <BreadcrumbsMobile>
          {showParents ? (
            lastCustomParent?.url ? (
              <StyledNextLink
                analyticsContext="breadcrumbs"
                analyticsName={lastCustomParent.url}
                href={lastCustomParent.url}
              >
                <StyledChevronLeftIcon />

                {lastCustomParent.name}
              </StyledNextLink>
            ) : lastParent ? (
              <StyledNextLink
                analyticsContext="breadcrumbs"
                analyticsName={lastParent}
                href={`${pathBeforeLastParent}${lastParent}`}
              >
                <StyledChevronLeftIcon />
                {unslugify(lastParent)}
              </StyledNextLink>
            ) : (
              showHome && <HomeCrumb hasChevronLeft />
            )
          ) : (
            <HomeCrumb hasChevronLeft />
          )}
        </BreadcrumbsMobile>

        <BreadcrumbsTabletAndDesktop>
          {showHome && (
            <Item>
              <HomeCrumb />

              <StyledChevronRightIcon />
            </Item>
          )}

          {showParents &&
            (customParents
              ? customParents.map((parent) => (
                  <Item key={parent.url}>
                    <StyledNextLink
                      analyticsContext="breadcrumbs"
                      analyticsName={parent.url}
                      href={parent.url}
                    >
                      {parent.name}
                    </StyledNextLink>

                    {showName && <StyledChevronRightIcon />}
                  </Item>
                ))
              : parentSlugs.map((slug) => {
                  const pathBeforeParent = completePath.substring(
                    0,
                    completePath.indexOf(slug),
                  )

                  if (!slug) {
                    return null
                  }

                  return (
                    <Item key={slug}>
                      <StyledNextLink
                        analyticsContext="breadcrumbs"
                        analyticsName={slug}
                        href={`${pathBeforeParent}${slug}`}
                      >
                        {unslugify(slug)}
                      </StyledNextLink>

                      {showName && <StyledChevronRightIcon />}
                    </Item>
                  )
                }))}

          {showName && (
            <Item>
              <Name>{customName?.name ?? nameSlug}</Name>
            </Item>
          )}
        </BreadcrumbsTabletAndDesktop>
      </BreadcrumbsWrapper>
    </>
  )
}

export default Breadcrumbs
