import { ProductCardFragment } from '@emico-hooks/product-card-fragment'
import { useTweakwiseRecommendationsFeatured } from '@emico-hooks/tweakwise-recommendations'
import { stripMaybes } from '@emico-utils/graphql-data-utils'
import React from 'react'

import { Container } from '@emico/ui'

import { CraftPageBlocksFragmentCraftProductRecommendationsEntry } from '../lib/craftFragments.generated'
import { useStoreConfig } from '../lib/storeConfig'
import CraftBlock from './CraftBlock'
import ProductSliderWithTracking from './ProductSliderWithTracking'
import SectionHeader from './SectionHeader'

function getSlidesToShowTablet(productCount: number) {
  switch (productCount) {
    case 1:
    case 2:
      return 2
    case 3:
      return 3
    default:
      return 3.5
  }
}

function getSlidesToShowDesktop(productCount: number) {
  switch (productCount) {
    case 1:
    case 2:
      return 2
    case 3:
      return 3
    default:
      return 4
  }
}

interface Props {
  block: CraftPageBlocksFragmentCraftProductRecommendationsEntry
}

const CraftProductRecommendationsBlock = ({ block }: Props) => {
  const { storeConfig } = useStoreConfig()
  const { data: tweakwiseItems } = useTweakwiseRecommendationsFeatured(
    block.tweakwiseTemplateId,
  )

  const disableEcommerce = storeConfig?.disableEcommerce ?? false
  const filteredProducts = tweakwiseItems
    ?.filter(stripMaybes)
    .map((item) => item.product as ProductCardFragment)
  const productCount = filteredProducts?.length ?? 0

  if (!filteredProducts || productCount === 0) {
    return null
  }

  return (
    <CraftBlock>
      <Container>
        {(block.blockTitle || block.subtitle) && (
          <SectionHeader
            title={block.blockTitle}
            subtitle={block.subtitle}
            hasLargeTitle
          />
        )}

        <ProductSliderWithTracking
          disableEcommerce={disableEcommerce}
          products={filteredProducts}
          slidesToShowMobile={productCount === 1 ? 1 : 1.5}
          slidesToShowTablet={getSlidesToShowTablet(productCount)}
          slidesToShowDesktop={getSlidesToShowDesktop(productCount)}
          trackingInfo={{
            itemListId: 'related_products',
            itemListName: block.blockTitle ?? 'Related products',
          }}
        />
      </Container>
    </CraftBlock>
  )
}

export default CraftProductRecommendationsBlock
