import { Youtube } from '@emico-react/youtube'
import styled from '@emotion/styled'
import React, { useState } from 'react'

import { minWidth } from '@emico/styles'
import { Container, Loader } from '@emico/ui'

import { CraftPageBlocksFragmentCraftAccordionInformationEntry } from '../lib/craftFragments.generated'
import theme from '../theme'
import Accordion, { AccordionHeader } from './Accordion'
import ButtonSecondary from './ButtonSecondary'
import CraftBlock from './CraftBlock'
import HtmlContent from './HtmlContent'
import SectionTitle from './SectionTitle'

const StyledContainer = styled(Container)`
  @media ${minWidth('lg')} {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
`

const Column = styled.div`
  @media ${minWidth('lg')} {
    grid-column: 3 / span 8;
  }
`

const Header = styled.header`
  padding-bottom: ${theme.spacing.md};
  border-bottom: ${theme.borders.default};
`

const StyledAccordion = styled(Accordion)`
  border-bottom: ${theme.borders.default};

  ${AccordionHeader} {
    padding: ${theme.spacing.md} 0;
  }
`

const ItemLabel = styled.span`
  font-weight: ${theme.fontWeights.bold};
  padding-right: ${theme.spacing.md};
`

const ItemContent = styled.div`
  padding-bottom: ${theme.spacing.lg};
`

const VideoContainer = styled('div', {
  shouldForwardProp: (prop) => !['isHidden'].includes(prop.toString()),
})<{ isHidden?: boolean }>`
  position: relative;
  margin-top: ${theme.spacing.lg};
  aspect-ratio: 16 / 9;
`

const LoaderWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.backgroundLight};
  z-index: 1;
`

const StyledYoutube = styled(Youtube, {
  shouldForwardProp: (prop) => !['isHidden'].includes(prop.toString()),
})<{ isHidden?: boolean }>`
  width: 100%;
  height: 100%;

  @media ${minWidth('md')} {
    visibility: ${({ isHidden }) => isHidden && 'hidden'};
    position: ${({ isHidden }) => isHidden && 'absolute'};
  }
`

interface Props {
  block: CraftPageBlocksFragmentCraftAccordionInformationEntry
}

const CraftAccordionInformationBlock = ({ block }: Props) => {
  const [isVideoReady, setIsVideoReady] = useState<boolean>(false)

  if (!block.informationItems || block.informationItems.length === 0) {
    return null
  }

  return (
    <CraftBlock>
      <StyledContainer>
        <Column>
          <Header>
            <SectionTitle title={block.blockTitle} hasLargeTitle />
          </Header>

          {block.informationItems.map((item) => {
            if (!item?.itemTitle || !item.text) {
              return null
            }

            return (
              <StyledAccordion
                key={item.itemTitle}
                label={<ItemLabel>{item.itemTitle}</ItemLabel>}
                labelElement="h3"
              >
                <ItemContent>
                  <HtmlContent html={item.text} />

                  {item.itemLink?.url && item.itemLink.customText && (
                    <ButtonSecondary
                      craftLink={item.itemLink}
                      analyticsContext="accordion-information"
                      analyticsName={item.itemLink.customText}
                      colorTheme="dark"
                    >
                      {item.itemLink.customText}
                    </ButtonSecondary>
                  )}

                  {item.youtubeId && (
                    <>
                      <VideoContainer isHidden={!isVideoReady}>
                        {!isVideoReady && (
                          <LoaderWrapper>
                            <Loader />
                          </LoaderWrapper>
                        )}

                        <StyledYoutube
                          videoId={item.youtubeId}
                          onReady={() => setIsVideoReady(true)}
                          isHidden={!isVideoReady}
                        />
                      </VideoContainer>
                    </>
                  )}
                </ItemContent>
              </StyledAccordion>
            )
          })}
        </Column>
      </StyledContainer>
    </CraftBlock>
  )
}

export default CraftAccordionInformationBlock
