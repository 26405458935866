import { ComponentSlider } from '@emico-react/component-slider'
import styled from '@emotion/styled'
import React, { ComponentProps } from 'react'

import { maxWidth, minWidth } from '@emico/styles'
import { Container } from '@emico/ui'

import { CraftPageBlocksFragmentCraftNewsEntry } from '../lib/craftFragments.generated'
import theme from '../theme'
import CraftBlock from './CraftBlock'
import CraftGenericBlockLink, { StyledCraftLink } from './CraftGenericBlockLink'
import SectionHeader from './SectionHeader'

const NewsItemsMobile = styled.div`
  @media ${minWidth('md')} {
    display: none;
  }
`

const NewsItemsTablet = styled.div`
  @media ${maxWidth('sm')} {
    display: none;
  }

  @media ${minWidth('lg')} {
    display: none;
  }
`

const NewsItemsDesktop = styled.div`
  @media ${maxWidth('md')} {
    display: none;
  }
`

const Grid = styled('div', {
  shouldForwardProp: (prop) => !['itemCount'].includes(prop.toString()),
})<{ itemCount: number }>`
  display: grid;
  grid-template-columns: ${({ itemCount }) =>
    `repeat(${itemCount === 1 ? 2 : itemCount}, 1fr)`};
  grid-gap: ${theme.spacing.md};
`

const StyledCraftGenericBlockLink = styled(CraftGenericBlockLink)`
  position: relative;
  aspect-ratio: 1 / 1;

  ${StyledCraftLink} {
    aspect-ratio: 1 / 1;
    width: 100%;
    height: auto;
  }
`

interface SliderProps
  extends Omit<ComponentProps<typeof ComponentSlider>, 'children'> {
  newsItems: Exclude<CraftPageBlocksFragmentCraftNewsEntry['newsItems'], null>
}

const Slider = ({ newsItems, ...other }: SliderProps) => {
  if (!newsItems) {
    return null
  }

  return (
    <ComponentSlider snapAlign="start" slideGap={15} {...other}>
      {newsItems.map(
        (newsItem, index) =>
          newsItem?.__typename === 'CraftGenericBlockLinkEntry' && (
            <StyledCraftGenericBlockLink item={newsItem} key={index} />
          ),
      )}
    </ComponentSlider>
  )
}

interface Props {
  block: CraftPageBlocksFragmentCraftNewsEntry
}

const CraftNewsBlock = ({ block }: Props) => {
  const itemsWithContentCount = block.newsItems?.filter(
    (item) =>
      item?.__typename === 'CraftGenericBlockLinkEntry' &&
      item.image?.[0] &&
      item.linkField,
  ).length

  if (
    !block.newsItems ||
    block.newsItems.length === 0 ||
    itemsWithContentCount === 0
  ) {
    return null
  }

  return (
    <CraftBlock>
      <Container>
        {(block.newsTitle || block.newsSubtitle) && (
          <SectionHeader
            title={block.newsTitle}
            subtitle={block.newsSubtitle}
            hasLargeTitle
          />
        )}

        <NewsItemsMobile>
          <Slider newsItems={block.newsItems} slidesToShow={1.25} />
        </NewsItemsMobile>

        <NewsItemsTablet>
          <Slider newsItems={block.newsItems} slidesToShow={2.5} />
        </NewsItemsTablet>

        <NewsItemsDesktop>
          {block.newsItems.length > 4 ? (
            <Slider newsItems={block.newsItems} slidesToShow={4} showArrows />
          ) : (
            <Grid itemCount={block.newsItems.length}>
              {block.newsItems.map(
                (newsItem, index) =>
                  newsItem?.__typename === 'CraftGenericBlockLinkEntry' && (
                    <StyledCraftGenericBlockLink item={newsItem} key={index} />
                  ),
              )}
            </Grid>
          )}
        </NewsItemsDesktop>
      </Container>
    </CraftBlock>
  )
}

export default CraftNewsBlock
