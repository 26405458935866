import { NextRouter } from 'next/router'

/**
 * Function to update URL with page key
 *
 * @param router Router from next/route useRouter()
 * @param pageKey Page key the URL should be updated with
 * @param hasNavigationScroll Boolean to allow or prevent a top-page scroll after router push
 */

export default function updateUrlWithPageKey(
  router: NextRouter,
  pageKey: number,
  hasNavigationScroll: boolean = true,
) {
  router.query.page = String(pageKey) ?? []

  router.push(
    {
      pathname: router.pathname,
      query: {
        ...router.query,
      },
    },
    undefined,
    {
      scroll: hasNavigationScroll,
    },
  )
}
