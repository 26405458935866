import { Picture } from '@emico-react/image'
import styled from '@emotion/styled'
import React, { ReactNode } from 'react'

import { minWidth } from '@emico/styles'
import { Container, H1 } from '@emico/ui'

import { CraftImageType } from '../lib/customTypes'
import getDate from '../lib/getDate'
import theme from '../theme'

const HEADER_HEIGHT_SMALL = '140px'
const HEADER_HEIGHT_MEDIUM = '200px'
const HEADER_HEIGHT_LARGE = '260px'
const HEADER_HEIGHT_EXTRA_LARGE = '300px'

const Header = styled.header`
  display: grid;
  align-items: center;
  justify-items: center;
`

const Figure = styled.figure`
  grid-row: 1;
  grid-column: 1;
  margin: 0;
  width: 100%;
  ${theme.stylingSnippet.transparentOverlay()};
`

const StyledPicture = styled(Picture, {
  shouldForwardProp: (prop) => !['isLow'].includes(prop.toString()),
})<{ isLow?: boolean }>`
  width: 100%;
  height: ${({ isLow }) =>
    isLow ? HEADER_HEIGHT_SMALL : HEADER_HEIGHT_MEDIUM};
  object-fit: cover;

  @media ${minWidth('md')} {
    height: ${({ isLow }) =>
      isLow ? HEADER_HEIGHT_MEDIUM : HEADER_HEIGHT_LARGE};
  }

  @media ${minWidth('lg')} {
    height: ${({ isLow }) =>
      isLow ? HEADER_HEIGHT_LARGE : HEADER_HEIGHT_EXTRA_LARGE};
  }
`

const TitleWrapper = styled.div`
  grid-row: 1;
  grid-column: 1;
  position: relative;
  text-align: center;
`

const Title = styled(H1, {
  shouldForwardProp: (prop) => !['hasSmallTitle'].includes(prop.toString()),
})<{ hasSmallTitle?: boolean }>`
  color: ${theme.colors.textLight};
  font-size: ${({ hasSmallTitle }) =>
    hasSmallTitle ? theme.fontSizes['2xl'] : theme.fontSizes['3xl']};
  font-weight: ${theme.fontWeights.bold};

  @media ${minWidth('md')} {
    font-size: ${theme.fontSizes['4xl']};
  }
`

const Subtitle = styled.p`
  margin: ${theme.spacing.xxs} 0 0;
  color: ${theme.colors.textLight};
  font-family: ${theme.fonts.heading};
  font-size: ${theme.fontSizes.lg};
  font-weight: ${theme.fontWeights.medium};
  text-transform: none;
`

const Time = styled.time`
  color: ${theme.colors.textLight};
  font-size: ${theme.fontSizes.xl};
`

interface Props {
  title: ReactNode
  image: CraftImageType
  subtitle?: ReactNode
  /**
   * Date to show underneath the title
   */
  date?: string
  /**
   * Should the title have a smaller font-size?
   */
  hasSmallTitle?: boolean
  /**
   * Should the header have a reduced height?
   */
  isLow?: boolean
}

const PageHeader = ({
  title,
  image,
  subtitle,
  date,
  hasSmallTitle,
  isLow,
}: Props) => {
  const dateReadable = date ? getDate(date) : undefined

  return (
    <Header>
      {image && (
        <Figure>
          <StyledPicture
            isLow={isLow}
            style={{}}
            breakpoints={{
              mobile: {
                url: image.mobileUrl ?? image.defaultUrl ?? '',
                width: theme.imageSizes.pageHeader.sizes.xs,
                height: theme.imageSizes.pageHeader.sizes.xs,
              },
              tablet: {
                url: image.tabletUrl ?? image.defaultUrl ?? '',
                width: theme.imageSizes.pageHeader.sizes.md,
                height: theme.imageSizes.pageHeader.sizes.md,
              },
              desktop: {
                url: image.desktopUrl ?? image.defaultUrl ?? '',
                width: theme.imageSizes.pageHeader.width,
                height: theme.imageSizes.pageHeader.width,
              },
            }}
            alt={image.title ?? ''}
            lazy={false}
          />
        </Figure>
      )}

      <TitleWrapper>
        <Container>
          <Title hasSmallTitle={hasSmallTitle}>{title}</Title>

          {subtitle && <Subtitle>{subtitle}</Subtitle>}

          {date && <Time dateTime={date}>{dateReadable}</Time>}
        </Container>
      </TitleWrapper>
    </Header>
  )
}

export default PageHeader
