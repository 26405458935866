import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { ReactNode } from 'react'

import { ChevronLeftIcon, ChevronRightIcon } from '@emico/icons'
import { minWidth } from '@emico/styles'

import theme from '../theme'

const PaginationList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(35px, max-content));
  justify-content: center;
  gap: ${theme.spacing.xxs};

  @media ${minWidth('lg')} {
    justify-content: right;
  }
`

const iconStyles = css`
  font-size: 10px;
`

const StyledChevronRightIcon = styled(ChevronRightIcon)`
  ${iconStyles};
`

const StyledChevronLeftIcon = styled(ChevronLeftIcon)`
  ${iconStyles};
`

export interface PaginationElementProps {
  current: number
  title: ReactNode
  isDivider?: boolean
  key?: string
}

interface Props {
  current: number
  total: number
  icons?: boolean
  showPreviousNext?: boolean
  renderElement: ({
    current,
    title,
    isDivider,
    key,
  }: PaginationElementProps) => ReactNode
}

const Pagination = ({
  total,
  current,
  icons,
  showPreviousNext = true,
  renderElement,
  ...props
}: Props) => {
  const hasNext = current !== 0 && current < total
  const hasPrev = current > 1
  const hasMany = total >= 5
  const isPrevVisible = current > 2
  const isNextVisible = current < total - 1
  const isFirst = current === 1
  const isLast = current === total
  const isCurrentVisible = current !== 1 && current !== total
  const isPrevDividerVisible = current >= 4
  const isNextDividerVisible = total - current >= 3

  if (total <= 1) {
    return null
  }

  return (
    <PaginationList {...props}>
      {showPreviousNext &&
        hasPrev &&
        renderElement({
          current: current - 1,
          title: <StyledChevronLeftIcon />,
        })}
      {hasMany ? (
        <>
          {renderElement({ current: 1, title: String(1) })}

          {isPrevDividerVisible &&
            renderElement({
              current: 0,
              title: '...',
              isDivider: true,
            })}

          {isLast &&
            renderElement({
              current: current - 2,
              title: String(current - 2),
            })}

          {isPrevVisible &&
            renderElement({
              current: current - 1,
              title: String(current - 1),
            })}

          {isCurrentVisible &&
            renderElement({ current, title: String(current) })}

          {isNextVisible &&
            renderElement({
              current: current + 1,
              title: String(current + 1),
            })}

          {isFirst &&
            renderElement({
              current: current + 2,
              title: String(current + 2),
            })}

          {isNextDividerVisible &&
            renderElement({
              current: 0,
              title: '...',
              isDivider: true,
            })}

          {renderElement({ current: total, title: String(total) })}
        </>
      ) : (
        Array.from(Array(total)).map((_, index) =>
          renderElement({
            current: index + 1,
            title: String(index + 1),
            key: index.toString(),
          }),
        )
      )}

      {showPreviousNext &&
        hasNext &&
        renderElement({
          current: current + 1,
          title: <StyledChevronRightIcon />,
        })}
    </PaginationList>
  )
}

export default Pagination
