import styled from '@emotion/styled'
import React from 'react'

import { BreakpointIdentifier, minWidth } from '@emico/styles'
import { Container } from '@emico/ui'

import { CraftPageBlocksFragmentCraftSimpleContentEntry } from '../lib/craftFragments.generated'
import theme from '../theme'
import CraftBlock from './CraftBlock'
import CraftLink from './CraftLink'
import HtmlContent from './HtmlContent'
import ReadMore from './ReadMore'
import SectionHeader from './SectionHeader'

enum ContainerGridEnum {
  FULL_WIDTH = 'fullWidth',
  NARROW_LEFT = 'narrowLeft',
  NARROW_CENTER = 'narrowCenter',
}

enum FontsizeEnum {
  NORMAL = 'normal',
  LARGE = 'large',
}

// Based on the current containerWidth dropdown value, the suitable grid styling is returned
const getDynamicGridValues = (
  containerWidth: string,
  breakpoint: BreakpointIdentifier.md | BreakpointIdentifier.lg,
) => {
  switch (containerWidth) {
    case ContainerGridEnum.NARROW_CENTER:
      return `${breakpoint === BreakpointIdentifier.md ? '2' : '3'} / span ${
        breakpoint === BreakpointIdentifier.md ? '10' : '8'
      }`
    case ContainerGridEnum.NARROW_LEFT:
      return `span ${breakpoint === BreakpointIdentifier.md ? '10' : '8'}`
    default:
      return null
  }
}

type ContainerProps = Pick<
  CraftPageBlocksFragmentCraftSimpleContentEntry,
  'containerWidth'
>

const StyledContainer = styled(Container, {
  shouldForwardProp: (prop) => !['containerWidth'].includes(prop.toString()),
})<ContainerProps>`
  @media ${minWidth('md')} {
    display: ${({ containerWidth }) =>
      containerWidth !== ContainerGridEnum.FULL_WIDTH && 'grid'};
    grid-template-columns: ${({ containerWidth }) =>
      containerWidth !== ContainerGridEnum.FULL_WIDTH && 'repeat(12, 1fr)'};
  }
`

const ContentWrapper = styled('article', {
  shouldForwardProp: (prop) => !['containerWidth'].includes(prop.toString()),
})<ContainerProps>`
  @media ${minWidth('md')} {
    grid-column: ${({ containerWidth }) =>
      containerWidth &&
      getDynamicGridValues(containerWidth, BreakpointIdentifier.md)};
  }

  @media ${minWidth('lg')} {
    grid-column: ${({ containerWidth }) =>
      containerWidth &&
      getDynamicGridValues(containerWidth, BreakpointIdentifier.lg)};
  }
`

const StyledCraftLink = styled(CraftLink)`
  display: inline-block;
  margin-top: ${theme.spacing.lg};
  align-self: flex-start;
  border-bottom: ${theme.borders.base} ${theme.colors.text};
  color: ${theme.colors.text};
  text-decoration: none;

  &:hover {
    color: ${theme.colors.text};
    text-decoration: none;
  }
`

const Content = ({
  richText,
  blockLink,
}: Pick<
  CraftPageBlocksFragmentCraftSimpleContentEntry,
  'richText' | 'blockLink'
>) => {
  if (!richText) {
    return null
  }

  return (
    <>
      <div>
        <HtmlContent html={richText} />
      </div>

      {blockLink?.url && blockLink.customText && (
        <StyledCraftLink
          craftLink={blockLink}
          analyticsContext="imageContentBanner"
          analyticsName={blockLink.customText}
        >
          {blockLink.customText}
        </StyledCraftLink>
      )}
    </>
  )
}

interface Props {
  block: CraftPageBlocksFragmentCraftSimpleContentEntry
}

const CraftSimpleContentBlock = ({ block }: Props) => {
  const title = block.blockTitleWithStyling?.[0]

  if (!block.richText) {
    return null
  }

  return (
    <CraftBlock>
      <StyledContainer containerWidth={block.containerWidth}>
        <ContentWrapper containerWidth={block.containerWidth}>
          {title && (
            <SectionHeader
              title={title?.text}
              hasLargeTitle={title.fontSize === FontsizeEnum.LARGE}
            />
          )}

          {block.readMoreMobile || block.readMoreTabletAndDesktop ? (
            <ReadMore
              readMoreMobile={Boolean(block.readMoreMobile)}
              readMoreLinesMobile={block.readMoreLinesMobile}
              readMoreTabletAndDesktop={Boolean(block.readMoreTabletAndDesktop)}
              readMoreLinesTabletAndDesktop={
                block.readMoreLinesTabletAndDesktop
              }
              readMoreText={block.readMoreText ?? undefined}
              readLessText={block.readLessText ?? undefined}
            >
              <Content richText={block.richText} blockLink={block.blockLink} />
            </ReadMore>
          ) : (
            <Content richText={block.richText} blockLink={block.blockLink} />
          )}
        </ContentWrapper>
      </StyledContainer>
    </CraftBlock>
  )
}

export default CraftSimpleContentBlock
