import { Youtube } from '@emico-react/youtube'
import React from 'react'

import { Container } from '@emico/ui'

import { CraftPageBlocksFragmentCraftYoutubeVideoEntry } from '../lib/craftFragments.generated'
import CraftBlock from './CraftBlock'
import VideoWithPlaceholder from './VideoWithPlaceholder'

interface Props {
  block: CraftPageBlocksFragmentCraftYoutubeVideoEntry
}

const CraftImageBlock = ({ block }: Props) => {
  const image = block.placeholder?.[0]

  if (!block.youtubeId) {
    return null
  }

  return (
    <CraftBlock>
      <Container>
        {image ? (
          <VideoWithPlaceholder
            videoId={block.youtubeId}
            placeholderImage={image}
          />
        ) : (
          <Youtube videoId={block.youtubeId} />
        )}
      </Container>
    </CraftBlock>
  )
}

export default CraftImageBlock
