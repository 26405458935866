import React, { SVGProps } from 'react'

const FilterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 12"
    fill="none"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M4.792 1.919a.958.958 0 100 1.917.958.958 0 000-1.917zm-2.712 0a2.876 2.876 0 015.424 0h6.871a.958.958 0 110 1.917H7.504a2.876 2.876 0 01-5.424 0H.958a.958.958 0 010-1.917H2.08zm8.462 5.75a.958.958 0 100 1.917.958.958 0 000-1.917zm-2.712 0a2.876 2.876 0 015.424 0h1.121a.958.958 0 110 1.917h-1.121a2.876 2.876 0 01-5.424 0H.958a.958.958 0 010-1.917H7.83z"
      fill="currentColor"
    />
  </svg>
)

export default FilterIcon
