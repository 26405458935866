import styled from '@emotion/styled'
import React from 'react'

import { minWidth } from '@emico/styles'
import { Container, H3 } from '@emico/ui'

import { CraftPageBlocksFragmentCraftFeaturedCategoriesEntry } from '../lib/craftFragments.generated'
import theme from '../theme'
import CraftBlock from './CraftBlock'
import CraftImage from './CraftImage'
import CraftLink from './CraftLink'
import SectionHeader from './SectionHeader'

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${theme.spacing.sm};

  @media ${minWidth('xs')} {
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${minWidth('md')} {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }

  @media ${minWidth('lg')} {
    grid-gap: ${theme.spacing.md};
  }
`

const CategoryHeader = styled.header`
  display: flex;
  align-items: flex-end;
  flex-shrink: 0;
  padding: 0 ${theme.spacing.xs};
`

const CategoryTitle = styled(H3)`
  font-size: ${theme.fontSizes.xs};
  text-align: center;

  @media ${minWidth('md')} {
    font-size: ${theme.fontSizes.xs};
  }

  @media ${minWidth('lg')} {
    font-size: ${theme.fontSizes.md};
  }
`

const StyledCraftLink = styled(CraftLink)`
  background: ${theme.colors.grayLight};
  position: relative;
  border-radius: ${theme.borderRadius.base};
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  @media ${minWidth('lg')} {
    ${theme.animation.linkHover('currentColor', '1px', 'h3')}
  }
`

const CategoryContent = styled.div`
  aspect-ratio: 1 / 1;
  padding: ${theme.spacing.sm} ${theme.spacing.xs};
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${minWidth('sm')} {
    padding: ${theme.spacing.lg};
  }

  @media ${minWidth('md')} {
    padding: ${theme.spacing.sm} ${theme.spacing.xs};
  }

  @media ${minWidth('lg')} {
    padding: ${theme.spacing.lg};
  }
`

const Figure = styled.figure`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 1;
  height: calc(100% - 30px);
  width: 100%;
  margin: auto 0;
  padding-bottom: ${theme.spacing.xs};
`

const StyledCraftImage = styled(CraftImage)`
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
  object-fit: contain;
`

interface Props {
  block: CraftPageBlocksFragmentCraftFeaturedCategoriesEntry
}

const CraftFeaturedCategoriesBlock = ({ block }: Props) => {
  const itemsWithContentCount = block.categories?.filter(
    (item) =>
      item?.__typename === 'CraftGenericBlockLinkEntry' &&
      item.image?.[0] &&
      item.linkField,
  ).length

  if (
    !block.categories ||
    block.categories.length === 0 ||
    itemsWithContentCount === 0
  ) {
    return null
  }

  return (
    <CraftBlock id={block.anchorLinkId ?? undefined}>
      <Container>
        {block.featuredCategoriesTitle && (
          <SectionHeader title={block.featuredCategoriesTitle} hasLargeTitle />
        )}

        <Grid>
          {block.categories.map((category, index) => {
            if (
              category?.__typename !== 'CraftGenericBlockLinkEntry' ||
              !category.linkField ||
              !category.image?.[0]
            ) {
              return null
            }

            return (
              <StyledCraftLink
                key={index}
                craftLink={category.linkField}
                analyticsContext="featuredCategories"
                analyticsName={`category-${category.title}`}
              >
                <CategoryContent>
                  <Figure>
                    <StyledCraftImage
                      image={category.image[0]}
                      width={300}
                      height={200}
                    />
                  </Figure>

                  {category.title && (
                    <CategoryHeader>
                      <CategoryTitle>{category.title}</CategoryTitle>
                    </CategoryHeader>
                  )}
                </CategoryContent>
              </StyledCraftLink>
            )
          })}
        </Grid>
      </Container>
    </CraftBlock>
  )
}

export default CraftFeaturedCategoriesBlock
