import { ButtonUnstyled } from '@emico-react/buttons'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { ComponentProps } from 'react'

import theme from '../theme'

const buttonStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  background-color: ${theme.colors.backgroundLight};
  border-radius: ${theme.borderRadius.base};
`

const StyledButtonUnstyled = styled(ButtonUnstyled, {
  shouldForwardProp: (prop) => !['isActive'].includes(prop.toString()),
})<{ isActive?: boolean }>`
  ${buttonStyles};
  background-color: ${({ isActive }) => isActive && theme.colors.grayMiddle};
`

const Divider = styled.div`
  ${buttonStyles};
`

interface Props extends ComponentProps<typeof ButtonUnstyled> {
  isDivider?: boolean
  isActive?: boolean
}

const PaginationButton = ({ children, isDivider, isActive, ...other }: Props) =>
  isDivider ? (
    <Divider>{children}</Divider>
  ) : (
    <StyledButtonUnstyled isActive={isActive} {...other}>
      {children}
    </StyledButtonUnstyled>
  )

export default PaginationButton
