import { t } from '@lingui/macro'

/**
 * @param date should start in the following format: "YYYY-MM-DD"
 * @returns {number} the date with the written out month name
 * @example
 * // returns "8 January 2023"
 * getDate("2023-01-08 15:16");
 */
const getDate = (date: string) => {
  /** monthNames are put inside the getDate function, otherwise it will not translate properly */
  const monthNames = [
    t({ message: 'January' }),
    t({ message: 'February' }),
    t({ message: 'March' }),
    t({ message: 'April' }),
    t({ message: 'May' }),
    t({ message: 'June' }),
    t({ message: 'July' }),
    t({ message: 'August' }),
    t({ message: 'September' }),
    t({ message: 'October' }),
    t({ message: 'November' }),
    t({ message: 'December' }),
  ]

  const year = Number(date.slice(0, 4))
  const month = Number(date.slice(5, 7))
  const day = Number(date.slice(8, 10))

  const monthName = monthNames[month - 1]

  return `${day} ${monthName} ${year}`
}

export default getDate
