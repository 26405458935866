import { ButtonUnstyled } from '@emico-react/buttons'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import React, { ReactNode, useState } from 'react'
import { Collapse } from 'react-collapse'
import { Transition } from 'react-transition-group'
import { TransitionStatus } from 'react-transition-group/Transition'

import { maxWidth, minWidth } from '@emico/styles'

import ChevronDownIcon from '../icons/ChevronDownIcon'
import ChevronUpIcon from '../icons/ChevronUpIcon'
import { opacityStates } from '../lib/transitionStates'
import theme from '../theme'

const ReadMoreWrapper = styled.div`
  .ReactCollapse--collapse {
    transition-property: ${theme.transition.properties.dimensions};
    transition-duration: ${theme.transition.durations.extraSlow};
    transition-timing-function: ${theme.transition.timingFunctions
      .cubicBezierSmooth};
  }
`

interface ContentProps
  extends Pick<
    Props,
    | 'readMoreMobile'
    | 'readMoreLinesMobile'
    | 'readMoreTabletAndDesktop'
    | 'readMoreLinesTabletAndDesktop'
    | 'blurColorType'
  > {
  showAll: boolean
}

const Content = styled('div', {
  shouldForwardProp: (prop) =>
    ![
      'showAll',
      'readMoreMobile',
      'readMoreLinesMobile',
      'readMoreTabletAndDesktop',
      'readMoreLinesTabletAndDesktop',
      'blurColorType',
    ].includes(prop.toString()),
})<ContentProps>`
  @media ${maxWidth('sm')} {
    ${({ readMoreMobile, readMoreLinesMobile, showAll }) =>
      readMoreMobile &&
      !showAll &&
      theme.stylingSnippet.lineClamp(readMoreLinesMobile ?? 3)}

    ${({ showAll, readMoreMobile, blurColorType }) =>
      readMoreMobile &&
      css`
        ${theme.stylingSnippet.textBlur(showAll, blurColorType)};
      `}
  }

  @media ${minWidth('md')} {
    ${({ readMoreTabletAndDesktop, readMoreLinesTabletAndDesktop, showAll }) =>
      readMoreTabletAndDesktop &&
      !showAll &&
      theme.stylingSnippet.lineClamp(readMoreLinesTabletAndDesktop ?? 5)}

    ${({ showAll, readMoreTabletAndDesktop, blurColorType }) =>
      readMoreTabletAndDesktop &&
      css`
        ${theme.stylingSnippet.textBlur(showAll, blurColorType)};
      `}
  }
`

const Buttons = styled.div`
  display: grid;
  text-align: center;
`

interface ButtonProps
  extends Pick<Props, 'readMoreMobile' | 'readMoreTabletAndDesktop'> {
  state: TransitionStatus
}

const StyledButtonUnstyled = styled(ButtonUnstyled, {
  shouldForwardProp: (prop) =>
    !['readMoreMobile', 'readMoreTabletAndDesktop', 'state'].includes(
      prop.toString(),
    ),
})<ButtonProps>`
  margin-top: ${theme.spacing.lg};
  width: 100%;
  opacity: ${({ state }) => opacityStates[state]};
  transition-property: ${theme.transition.properties.common};
  transition-duration: ${theme.transition.durations.extraSlow};
  transition-timing-function: ${theme.transition.timingFunctions
    .cubicBezierSmooth};
  grid-row: 1;
  grid-column: 1;

  @media ${maxWidth('sm')} {
    display: ${({ readMoreMobile }) => !readMoreMobile && 'none'};
  }

  @media ${minWidth('md')} {
    display: ${({ readMoreTabletAndDesktop }) =>
      !readMoreTabletAndDesktop && 'none'};
  }
`

const iconStyling = css`
  font-size: 14px;
  margin-left: ${theme.spacing.xs};
`

const StyledChevronDownIcon = styled(ChevronDownIcon)`
  ${iconStyling};
`

const StyledChevronUpIcon = styled(ChevronUpIcon)`
  ${iconStyling};
`

interface Props {
  children: ReactNode
  readMoreMobile: boolean
  readMoreLinesMobile: number
  readMoreTabletAndDesktop?: boolean
  readMoreLinesTabletAndDesktop?: number
  readMoreText?: string
  readLessText?: string
  blurColorType?: 'none' | 'light' | 'dark'
}

const ReadMore = ({
  children,
  readMoreMobile,
  readMoreLinesMobile,
  readMoreTabletAndDesktop = false,
  readMoreLinesTabletAndDesktop,
  readMoreText,
  readLessText,
  blurColorType = 'none',
  ...other
}: Props) => {
  const [showAll, setShowAll] = useState<boolean>(false)

  return (
    <ReadMoreWrapper {...other}>
      <>
        <Collapse isOpened>
          <Content
            showAll={showAll}
            readMoreMobile={readMoreMobile ?? undefined}
            readMoreTabletAndDesktop={readMoreTabletAndDesktop ?? undefined}
            readMoreLinesMobile={readMoreLinesMobile}
            readMoreLinesTabletAndDesktop={readMoreLinesTabletAndDesktop}
            blurColorType={blurColorType}
          >
            {children}
          </Content>
        </Collapse>

        <Buttons>
          <Transition
            in={!showAll}
            timeout={800}
            unmountOnExit={Boolean(readLessText)}
            mountOnEnter={Boolean(readLessText)}
          >
            {(state: TransitionStatus) => (
              <StyledButtonUnstyled
                state={state}
                analyticsContext="simpleContent"
                analyticsName="readMore"
                onPress={() => setShowAll(true)}
                readMoreMobile={readMoreMobile ?? undefined}
                readMoreTabletAndDesktop={readMoreTabletAndDesktop ?? undefined}
              >
                {readMoreText ?? <Trans>Read more</Trans>}

                <StyledChevronDownIcon />
              </StyledButtonUnstyled>
            )}
          </Transition>

          {readLessText && (
            <Transition in={showAll} timeout={800} unmountOnExit mountOnEnter>
              {(state: TransitionStatus) => (
                <StyledButtonUnstyled
                  state={state}
                  analyticsContext="simpleContent"
                  analyticsName="readLess"
                  onPress={() => setShowAll(false)}
                  readMoreMobile={readMoreMobile ?? undefined}
                  readMoreTabletAndDesktop={
                    readMoreTabletAndDesktop ?? undefined
                  }
                >
                  {readLessText}

                  <StyledChevronUpIcon />
                </StyledButtonUnstyled>
              )}
            </Transition>
          )}
        </Buttons>
      </>
    </ReadMoreWrapper>
  )
}

export default ReadMore
