import styled from '@emotion/styled'
import React from 'react'

import { BreakpointIdentifier, minWidth } from '@emico/styles'
import { Container } from '@emico/ui'

import { CraftPageBlocksFragmentCraftTextColumnsEntry } from '../lib/craftFragments.generated'
import Unwrap from '../lib/UnWrap'
import theme from '../theme'
import CraftBlock from './CraftBlock'
import HtmlContent from './HtmlContent'
import ReadMore from './ReadMore'
import SectionHeader from './SectionHeader'

enum ContainerGridEnum {
  FULL_WIDTH = 'fullWidth',
  NARROW_LEFT = 'narrowLeft',
  NARROW_CENTER = 'narrowCenter',
}

enum ColorThemeEnum {
  NONE = 'none',
  LIGHT = 'light',
}

// Based on the current containerWidth dropdown value, the suitable grid styling is returned
const getDynamicGridValues = (
  containerWidth: string,
  breakpoint: BreakpointIdentifier.md | BreakpointIdentifier.lg,
) => {
  switch (containerWidth) {
    case ContainerGridEnum.NARROW_CENTER:
      return `${breakpoint === BreakpointIdentifier.md ? '2' : '3'} / span ${
        breakpoint === BreakpointIdentifier.md ? '10' : '8'
      }`
    case ContainerGridEnum.NARROW_LEFT:
      return `span ${breakpoint === BreakpointIdentifier.md ? '10' : '8'}`
    default:
      return null
  }
}

const getBackgroundColor = (colorTheme?: string) => {
  switch (colorTheme) {
    case ColorThemeEnum.LIGHT:
      return theme.colors.grayLight
    default:
      return undefined
  }
}

const StyledCraftBlock = styled(CraftBlock, {
  shouldForwardProp: (prop) => !['colorTheme'].includes(prop.toString()),
})<{ colorTheme?: string }>`
  padding: ${theme.spacing['3xl']} 0;
  background-color: ${({ colorTheme }) => getBackgroundColor(colorTheme)};
`

type ContainerProps = Pick<
  CraftPageBlocksFragmentCraftTextColumnsEntry,
  'containerWidth'
>

const StyledContainer = styled(Container, {
  shouldForwardProp: (prop) => !['containerWidth'].includes(prop.toString()),
})<ContainerProps>`
  @media ${minWidth('md')} {
    display: ${({ containerWidth }) =>
      containerWidth !== ContainerGridEnum.FULL_WIDTH && 'grid'};
    grid-template-columns: ${({ containerWidth }) =>
      containerWidth !== ContainerGridEnum.FULL_WIDTH && 'repeat(12, 1fr)'};
  }
`

const Article = styled('article', {
  shouldForwardProp: (prop) =>
    !['containerWidth', 'itemCount'].includes(prop.toString()),
})<ContainerProps>`
  @media ${minWidth('md')} {
    grid-column: ${({ containerWidth }) =>
      containerWidth &&
      getDynamicGridValues(containerWidth, BreakpointIdentifier.md)};
  }

  @media ${minWidth('lg')} {
    grid-column: ${({ containerWidth }) =>
      containerWidth &&
      getDynamicGridValues(containerWidth, BreakpointIdentifier.lg)};
  }
`

const Grid = styled('div', {
  shouldForwardProp: (prop) =>
    !['itemCount', 'hasReadMore'].includes(prop.toString()),
})<{ itemCount: number; hasReadMore?: boolean }>`
  display: ${({ hasReadMore }) => !hasReadMore && 'grid'};
  grid-gap: ${theme.spacing.md};

  @media ${minWidth('md')} {
    display: ${({ hasReadMore }) => hasReadMore && 'grid'};
    grid-template-columns: ${({ itemCount }) =>
      `repeat(${itemCount === 1 ? 2 : itemCount}, 1fr)`};
  }
`

const StyledColumn = styled('div', {
  shouldForwardProp: (prop) => !['hasReadMore'].includes(prop.toString()),
})<{ hasReadMore?: boolean }>`
  margin-bottom: ${({ hasReadMore }) => hasReadMore && theme.spacing.md};

  &:last-of-type {
    margin-bottom: ${({ hasReadMore }) => hasReadMore && 0};
  }

  @media ${minWidth('md')} {
    margin-bottom: 0;
  }

  p {
    font-size: ${theme.fontSizes.sm};
  }
`

type ColumnType = Unwrap<
  Exclude<CraftPageBlocksFragmentCraftTextColumnsEntry['textColumns'], null>
>

interface ColumnProps {
  column: ColumnType
  hasReadMore?: boolean
}

const Column = ({ column, hasReadMore }: ColumnProps) => {
  if (!column?.text) {
    return null
  }

  return (
    <StyledColumn hasReadMore={hasReadMore}>
      {column?.columnTitle && <SectionHeader title={column.columnTitle} />}

      <HtmlContent html={column.text} />
    </StyledColumn>
  )
}

interface Props {
  block: CraftPageBlocksFragmentCraftTextColumnsEntry
}

const CraftTextColumnsBlock = ({ block }: Props) => {
  if (!block.textColumns || block.textColumns?.length === 0) {
    return null
  }

  return (
    <StyledCraftBlock colorTheme={block.colorTheme ?? undefined}>
      <StyledContainer containerWidth={block.containerWidth}>
        <Article containerWidth={block.containerWidth}>
          {block.readMoreMobile ? (
            <ReadMore
              readMoreMobile={block.readMoreMobile}
              readMoreLinesMobile={block.readMoreLinesMobile}
              readMoreText={block.readMoreText ?? undefined}
              readLessText={block.readLessText ?? undefined}
              blurColorType="light"
            >
              <Grid itemCount={block.textColumns.length} hasReadMore>
                {block.textColumns.map((column, index) => (
                  <Column key={index} column={column} hasReadMore />
                ))}
              </Grid>
            </ReadMore>
          ) : (
            <Grid itemCount={block.textColumns.length}>
              {block.textColumns.map((column, index) => (
                <Column key={index} column={column} />
              ))}
            </Grid>
          )}
        </Article>
      </StyledContainer>
    </StyledCraftBlock>
  )
}

export default CraftTextColumnsBlock
