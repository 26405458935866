import React, { SVGProps } from 'react'

const ChevronUpIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14.03 7.06"
    aria-hidden="true"
    {...props}
  >
    <path
      d="M.08 5.6a.93.93 0 01.28-.37l6-5a1 1 0 011.27 0l6 4.83a1 1 0 01.27.3.93.93 0 01.12.39.94.94 0 010 .41 1.07 1.07 0 01-.2.36 1.05 1.05 0 01-.3.25 1 1 0 01-.37.11.85.85 0 01-.39 0 1.06 1.06 0 01-.35-.18L7 2.35 1.64 6.83A1.11 1.11 0 011.3 7a.87.87 0 01-.39 0 1 1 0 01-.38-.11 1 1 0 01-.3-.24A1 1 0 010 6.06a1 1 0 01.08-.46z"
      fill="currentColor"
    />
  </svg>
)

export default ChevronUpIcon
