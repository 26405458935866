import styled from '@emotion/styled'
import React from 'react'

import { Container } from '@emico/ui'

import { CraftPageBlocksFragmentCraftImageEntry } from '../lib/craftFragments.generated'
import { imageSizes } from '../theme/sizes'
import CraftBlock from './CraftBlock'
import CraftImage from './CraftImage'

const Figure = styled.figure`
  margin: 0;
`

const StyledCraftImage = styled(CraftImage)`
  width: 100%;
`

interface Props {
  block: CraftPageBlocksFragmentCraftImageEntry
}

const CraftImageBlock = ({ block }: Props) => {
  const image = block.image?.[0]

  return (
    <CraftBlock>
      <Container>
        <Figure>
          <StyledCraftImage image={image} preset={imageSizes.fullWidth} />
        </Figure>
      </Container>
    </CraftBlock>
  )
}

export default CraftImageBlock
