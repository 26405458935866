import { Image } from '@emico-react/image'
import { Youtube, YoutubeEvent, YoutubePlayer } from '@emico-react/youtube'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { useEffect, useRef, useState } from 'react'
import { Transition, TransitionStatus } from 'react-transition-group'

import { PlayCircleIcon } from '@emico/icons'
import { minWidth } from '@emico/styles'
import { ButtonUnstyled } from '@emico/ui'

import { CraftImageType } from '../lib/customTypes'
import { opacityStates } from '../lib/transitionStates'
import theme from '../theme'
import CraftImage from './CraftImage'

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
`

const Figure = styled.figure`
  margin: 0;
  ${theme.stylingSnippet.transparentOverlay()};
`

const StyledPlayCircleIcon = styled(PlayCircleIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${theme.colors.primary};
  font-size: 30px;
  pointer-events: none;
  z-index: 1;

  @media ${minWidth('lg')} {
    font-size: 50px;
    transition-property: ${theme.transition.properties.common};
    transition-duration: ${theme.transition.durations.extraSlow};
    transition-timing-function: ${theme.transition.timingFunctions
      .cubicBezierSmooth};
  }
`

const imageStyling = css`
  width: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;
`

export const StyledImage = styled(Image)`
  ${imageStyling};
`

const StyledCraftImage = styled(CraftImage)`
  ${imageStyling};
`

const StyledButtonUnstyled = styled(ButtonUnstyled, {
  shouldForwardProp: (prop) => !['state'].includes(prop.toString()),
})<{ state: TransitionStatus }>`
  transition-property: ${theme.transition.properties.common};
  transition-duration: ${theme.transition.durations.extraSlow};
  transition-timing-function: ${theme.transition.timingFunctions
    .cubicBezierSmooth};
  opacity: ${({ state }) => opacityStates[state]};
  aspect-ratio: 16 / 9;
  position: absolute;
  z-index: 1;
  width: 100%;

  @media ${minWidth('lg')} {
    &:hover {
      ${StyledPlayCircleIcon} {
        transform: translate(-50%, -50%) scale(1.2);
      }
    }
  }
`

interface Props {
  videoId?: string
  placeholderImage: CraftImageType | string
  className?: string
}

const VideoWithPlaceholder = ({
  videoId,
  placeholderImage,
  className,
  ...other
}: Props) => {
  const nodeRef = useRef(null)
  const [video, setVideo] = useState<YoutubePlayer | null>(null)
  const [showVideo, setShowVideo] = useState<boolean>(false)

  const handleVideoReady = (event: YoutubeEvent) => {
    setVideo(event.target)
  }

  // Play video if placeholder image is clicked
  useEffect(() => {
    if (showVideo) {
      video?.playVideo()
    }
  }, [showVideo, video])

  if (!videoId) {
    return null
  }

  return (
    <VideoWrapper className={className}>
      <Transition
        nodeRef={nodeRef}
        in={!showVideo}
        timeout={800}
        unmountOnExit
        mountOnEnter
      >
        {(state: TransitionStatus) => (
          <StyledButtonUnstyled
            state={state}
            analyticsContext="video-with-placeholder"
            analyticsName={`show-video-${videoId}`}
            onClick={() => {
              setShowVideo(true)
            }}
          >
            <Figure>
              {typeof placeholderImage === 'string' ? (
                <StyledImage
                  url={placeholderImage}
                  alt="product-video"
                  lazy={false}
                  sizes={theme.imageSizes.productGalleryImage.sizes}
                />
              ) : (
                <StyledCraftImage
                  image={placeholderImage}
                  sizes={theme.imageSizes.productGalleryImage.sizes}
                />
              )}
              <StyledPlayCircleIcon />
            </Figure>
          </StyledButtonUnstyled>
        )}
      </Transition>

      <Youtube
        videoId={videoId}
        onReady={(event) => handleVideoReady(event)}
        {...other}
      />
    </VideoWrapper>
  )
}

export default VideoWithPlaceholder
