import React from 'react'
import { BreadcrumbList as BreadcrumbListSchema, ListItem } from 'schema-dts'

import SchemaJsonLd from './SchemaJsonLd'

type BreadcrumbItem = {
  url: string
  name: string
}

interface Props extends Partial<BreadcrumbListSchema> {
  items: BreadcrumbItem[]
}

const SchemaBreadcrumbs = ({ items, ...other }: Props) => {
  const breadcrumbItems: ListItem[] = items.map((item, index) => ({
    '@type': 'ListItem',
    position: index + 1,
    item: {
      '@id': item.url,
      name: item.name,
    },
  }))

  return (
    <SchemaJsonLd<BreadcrumbListSchema>
      item={{
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: breadcrumbItems,
        ...other,
      }}
    />
  )
}

export default SchemaBreadcrumbs
