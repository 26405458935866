import { NextRouter } from 'next/router'

import { absolutify } from '@emico/url'

/**
 * Function to get pagination URL with page key
 *
 * @param router from next/route useRouter()
 * @param pageKey Page key the pagination URL should include
 */

export default function getPaginationUrl(router: NextRouter, pageKey: number) {
  const { slug, page, ...routerQuery } = router.query
  const hasRouterQuery = Object.keys(routerQuery).length !== 0
  const pageParam = router.query.page as string
  const baseUrl = router.asPath
    .replace(`?page=${pageParam}`, '')
    .replace(`&page=${pageParam}`, '')

  const paginationUrl = `${baseUrl}${
    hasRouterQuery ? '&page=' : '?page='
  }${pageKey}`

  return absolutify(paginationUrl)
}
