import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { Fragment } from 'react'

import { Maybe } from '@emico/graphql-schema-types'
import { minWidth } from '@emico/styles'
import { H3, H4 } from '@emico/ui'

import { CraftPageBlocksFragmentCraftCompareSpecificationsEntry } from '../lib/craftFragments.generated'
import Unwrap from '../lib/UnWrap'
import theme from '../theme'
import CraftImage from './CraftImage'
import HtmlContent from './HtmlContent'
import NextLinkPrimary from './NextLinkPrimary'

const Column = styled.div`
  display: grid;
  grid-template-rows: auto minmax(92px, auto) auto 1fr auto;
  justify-items: start;

  @media ${minWidth('lg')} {
    grid-template-rows: auto minmax(75px, auto) 1fr auto;
  }
`

const ColumnHeader = styled.header`
  margin-bottom: ${theme.spacing.md};
`

const Figure = styled.figure`
  margin-bottom: ${theme.spacing.lg};
`

const imageStyling = css`
  max-width: 100%;
  height: auto;
`

const StyledCraftImage = styled(CraftImage)`
  ${imageStyling};
  aspect-ratio: 16 / 9;
  object-fit: contain;
`

const StyledCraftImageSvg = styled(CraftImage)`
  ${imageStyling};
  margin-bottom: ${theme.spacing.lg};
`

const Title = styled(H3)`
  font-size: ${theme.fontSizes.lg};
  font-weight: ${theme.fontWeights.bold};
  text-transform: uppercase;
`

const Subtitle = styled(H4)`
  text-transform: uppercase;
`

const Text = styled.p`
  margin-bottom: ${theme.spacing.lg};
`

const List = styled.dl`
  margin-bottom: ${theme.spacing.xl};
`

const SpecLabel = styled.dt`
  font-weight: ${theme.fontWeights.bold};
  text-transform: uppercase;
`

const SpecValue = styled('dd', {
  shouldForwardProp: (prop) => !['showLabels'].includes(prop.toString()),
})<{ showLabels?: Maybe<boolean> }>`
  margin-bottom: ${({ showLabels }) =>
    showLabels ? theme.spacing.lg : theme.spacing.sm};
  padding-bottom: ${({ showLabels }) => !showLabels && theme.spacing.sm};

  &:not(:last-of-type) {
    border-bottom: ${({ showLabels }) => !showLabels && theme.borders.default};
  }

  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  p {
    margin: 0;
  }
`

const StyledNextLinkPrimary = styled(NextLinkPrimary)`
  font-weight: ${theme.fontWeights.bold};
`

type ColumnType = Unwrap<
  Maybe<
    Exclude<
      CraftPageBlocksFragmentCraftCompareSpecificationsEntry['columns'],
      null
    >
  >
>

interface Props {
  column: ColumnType
  showLabels?: Maybe<boolean>
}

const CraftCompareSpecificationsColumn = ({
  column,
  showLabels,
  ...other
}: Props) => {
  const image = column?.image?.[0]

  if (!column) {
    return null
  }

  return (
    <Column {...other}>
      {image?.url?.endsWith('svg') ? (
        <StyledCraftImageSvg image={image} height={200} />
      ) : (
        <Figure>
          <StyledCraftImage image={image} height={200} />
        </Figure>
      )}

      <ColumnHeader>
        <Title>{column?.columnTitle}</Title>

        {column?.subtitle && <Subtitle>{column.subtitle}</Subtitle>}
      </ColumnHeader>

      <Text>{column.text}</Text>

      <List>
        {column.specifications?.map((specification, index) => {
          if (!specification?.specificationValue) {
            return null
          }

          return (
            <Fragment key={index}>
              {specification?.specificationLabel && (
                <SpecLabel>{specification?.specificationLabel}</SpecLabel>
              )}

              <SpecValue showLabels={showLabels}>
                <HtmlContent html={specification.specificationValue} />
              </SpecValue>
            </Fragment>
          )
        })}
      </List>

      {column.columnLink?.url && column.columnLink.customText && (
        <StyledNextLinkPrimary
          href={column.columnLink.url}
          analyticsContext="compare.specifications.column"
          analyticsName={`link-${column.columnLink.customText}`}
          isBasic
        >
          {column.columnLink.customText}
        </StyledNextLinkPrimary>
      )}
    </Column>
  )
}

export default CraftCompareSpecificationsColumn
