import styled from '@emotion/styled'
import React from 'react'

import {
  CraftCategoryProductPageBlocksFragmentCraftHtml2Entry,
  CraftPageBlocksFragmentCraftHtmlEntry,
} from '../lib/craftFragments.generated'
import Container from './Container'
import CraftBlock from './CraftBlock'
import HtmlContent from './HtmlContent'
import SectionHeader from './SectionHeader'

const Text = styled.p`
  margin: 0;
  max-width: 750px;
`

interface Props {
  block:
    | CraftPageBlocksFragmentCraftHtmlEntry
    | CraftCategoryProductPageBlocksFragmentCraftHtml2Entry
}

const CraftHtmlBlock = ({ block }: Props) => {
  if (!block.code) {
    return null
  }

  return (
    <CraftBlock>
      <Container>
        <SectionHeader title={block.blockTitle} hasLargeTitle>
          {block.text && <Text>{block.text}</Text>}
        </SectionHeader>

        <HtmlContent html={block.code} />
      </Container>
    </CraftBlock>
  )
}

export default CraftHtmlBlock
